import React, { useContext, useRef } from 'react'
import { Link } from 'gatsby'
import { menuState } from './layout'
import { useSpring, useTransition, useChain, useTrail, animated } from 'react-spring'




export const Menu = () => 
    { 

        const { setDisplay, setMenuActive, state } = useContext(menuState)
        const linkRef = useRef()
        const menuRef = useRef()

    const transitions = useTransition(state.menuActive, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    })

        const Links = [
            { path: '/', page: 'Home'},
            { path: '/portfolio', page: 'portfolio' },
            { path: '/blog', page: 'blog'},
            { path: '/about', page: 'About' },
            //{ path: '/contact', page: 'Contact' }  Not sure if I want to build out a contact form when linkedin works just fine.
        ]

        const trails = useTrail(Links.length, {
            opacity: 1,
            transform: 'translate3d(0,0,0)',
            from: {
                opacity: 0,
                transform: 'translate3d(0,-40px,0)'
            },
            ref: linkRef
        })

        useChain([menuRef, linkRef])
        
        return (
            <nav className="main-menu nav justify-content-center" style={{display: state.display, zIndex: 9998}}>
            <animated.section className="navigation-container col-md-4 col-sm-8 col-xs-12">
                <ul className="menu-links ">
        {trails.map((props, i) => { return <animated.li key={i} style={props}><Link to={Links[i].path}>{Links[i].page}</Link></animated.li>})}

                    <li><button className="btn btn-primary back-btn center-block" onClick={() => {
                        setDisplay('none')
                        setMenuActive(false)
                    }}> Back</button></li>
                </ul>
            </animated.section>
        </nav>
    )}





