/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO ({ description, lang, image, keywords, title, summary, url }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const website = url
  let src = 'https://storage.googleapis.com/berato/public/berato.me.jpg'
  if (image !== undefined) src = image

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
    >
      {/** Meta Tags */}
      <title>{title}</title>
      <meta name='keywords' content={JSON.stringify(keywords)} />
      <meta name='author' content='Berato Wilson' />
      <meta name='description' content={`${metaDescription}`} />
      <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0' />
      {/* Facebook and LinkedIn */}
      <meta property='og:title' content={`${title}`} />
      <meta property='og:url' content={`${website}`} />
      <meta property='og:description' content={`${metaDescription}`} />
      <meta property='og:image' content={src} />
      <meta property='og:site_name' content='Front End Developer Portfolio of Berato Wilson' />
      <meta property='og:type' content='article' />

      {/** Twitter */}
      <meta name='twitter:card' content={`${summary}`} />
      <meta name='twitter:title' content={`${title}`} />
      <meta name='twitter:description' content={`${metaDescription}`} />
      <meta name='twitter:url' content={`${website}`} />
      <meta name='twitter:image' content={src} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: ''
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO
