/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState }from "react"
import { Helmet } from 'react-helmet'
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Logo from './Logo'
import { Menu } from './menu'
import 'bootstrap/dist/css/bootstrap.css'
import "./../main.scss"

export const menuState = React.createContext(null)
export const logoState = React.createContext(null)

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const defaultState = { display: 'none', menuActive: false, logoActive: true }

  const [state, setState] = useState(defaultState)


  const logoActive = props.logoActive !== null || props.logoActive !== undefined ? props.logoActive : true
  
  return (
    <>
      <Helmet>
        <script src="https://use.fontawesome.com/8b9c4fc955.js" />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,400,600|Cardo&display=swap:400"
          rel="stylesheet"
        />
        <link
          rel="shortcut icon"
          href="https://storage.googleapis.com/berato/public/favicon.ico"
          type="image/x-icon"
        />
        <link
          rel="icon"
          sizes="16x16 32x32"
          href="https://storage.googleapis.com/berato/public/favicon.ico?v=2"
        />
        <script src="https://unpkg.com/flickity@2.0/dist/flickity.pkgd.min.js"></script>
        <script src="//cdnjs.cloudflare.com/ajax/libs/velocity/1.5.0/velocity.min.js"></script>
      </Helmet>
      {/*<Header siteTitle={data.site.siteMetadata.title} /> */}
      <menuState.Provider
        value={{
          state,
          setMenuActive: menuActive => {
            setState({ ...defaultState, ...{ menuActive } })
          },
          setDisplay: display => {
            setState({ ...defaultState, ...{ display } })
          },
        }}
      >
        <div className="app">
          <a
            href="#"
            style={{ opacity: state.menuActive ? 0 : 1 }}
            className="main-menu-btn d-flex justify-content-center"
            onClick={() => {
              setState({
                ...defaultState,
                ...{
                  display: "flex",
                  menuActive: true,
                  viewingPortfolio: false,
                },
              })
            }}
          >
            Explore
          </a>
          <Menu />
          {<Logo logoActive={logoActive} />}
          <main>{props.children}</main>
          <footer className="footer"></footer>
        </div>
      </menuState.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
